import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 60px;
  border: solid 2px #bef1e5;
  border-radius: 5px;
  padding: 45px;

  .intro-txt {
    font-size: 30px;
    line-height: 1;
    color: #ff9600;
    font-weight: 600;
  }

  .detail-txt {
    line-height: 1.8;
  }

  .btn-wrap {
    display: flex;
    width: 100%;
    justify-content: center;

    a {
      border-radius: 25vh;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      background: #19ba9b;
      border: solid 2px #19ba9b;
      width: 200px;
      height: 50px;

      &:hover {
        opacity: 1;
        background: #ffffff;
        color: #3ec7b3;
        text-decoration: underline solid rgba(255, 255, 255, 0);
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：製品誘導案
 * @constructor
 */
const Introduction001 = () => {
  const routeParam = useRouteParam('https://sign-up.kintaicloud.jp/sign-up')
  return (
    <Wrapper>
      <p className="intro-txt">勤怠管理でお困りのことはありませんか？</p>
      <p className="detail-txt">
        「キンクラ」では、「自社」「常駐先」の両方の勤怠時間を
        <br />
        法律を守りながら管理できます。
        <br />
        アカウントを作成すれば、すぐにご利用いただけます。
      </p>
      <div className="btn-wrap">
        <a href={routeParam} target="_blank" rel="noreferrer">
          アカウントを作成
        </a>
      </div>
    </Wrapper>
  )
}

export default Introduction001
