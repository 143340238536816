import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction001 from './Introduction001'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0042 = () => (
  <Wrapper>
    <ColumnH1 label="2022年4月から順次施行開始！「改正育児・介護休業法」とは" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.09.27</p>
    <section>
      <img src="/images/column/details/c0042.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        育児・介護休業法の内容が改正され、令和4年4月より順次新制度が適用される運びとなりました。時代の流れに沿って幾度となく改正を繰り返している同法律ですが、今回の改正では、男性の育児休業取得を促進するための制度や、これまでよりも更に育児休業を取りやすいような体制づくりの後押し、育児休業のフレキシブルな取得方法など、育児休業の取得率を高めるための施策が目白押しとなっています。
        <br />
        今回は、改正内容や具体的な会社側の対応法について、順を追って解説をしていきましょう。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">育児・介護休業法が改正されるまでの経緯</a>
          </li>
          <li>
            <a href="#toc2">育児・介護休業法の改正内容</a>
            <ul>
              <li>
                <a href="#toc2-1">
                  育休を取得しやすい環境づくりの義務化（令和4年4月1日施行）
                </a>
              </li>
              <li>
                <a href="#toc2-2">
                  有期雇用者の育児・介護休業取得要件拡大（令和4年4月1日施行）
                </a>
              </li>
              <li>
                <a href="#toc2-3">
                  産後パパ育休制度の新設（令和4年10月1日施行）
                </a>
              </li>
              <li>
                <a href="#toc2-4">育児休業の分割取得（令和4年10月1日施行）</a>
              </li>
              <li>
                <a href="#toc2-5">
                  育児休業取得状況の公表（令和5年4月1日施行）
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#toc3">まとめ</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>育児・介護休業法が改正されるまでの経緯</h1>
      </div>
      <p>
        育児・介護休業法は、育児・介護生活と両立させながら仕事をする労働者が、安心して生活をすることができるような支援制度が定められています。
        <br />
        <br />
        育児休業は、原則として<span>1歳に満たない子供を育てる労働者</span>
        が希望した場合に取得することが可能となる休業のことです。子供が1歳になるまでに預け先の保育園が決まらなかった場合などは1歳6ヶ月まで、さらに状況が変わらない場合については2歳になるまで
        <span>休業期間を延長することもできます</span>。
        <br />
        育児休業は、男女の性別を問わず利用できる制度になりますが、特に男性労働者については、育児休業取得率の低さが問題視されています。ワーク・ライフ・バランスの提唱などの影響を受け、働きながら育児を行う女性労働者が増加している中、男性労働者の育児休業取得率の数値からは、依然として育児や家事の負担が女性に集中しがちな状況であることを見て取ることができます。
        <br />
        <br />
        一方、介護休業とは、要介護認定を受けた家族の介護を行うため、労働者が希望した場合に取得することが可能となる休業のことで、取得できる日数は
        <span>介護対象者1人につき通算93日まで、3回に分割して取得</span>
        することができます。
        <br />
        超高齢社会を迎えた昨今では、特に働き盛りといわれる40～50代が介護を担うケースが増加しています。中には
        <span>介護の負担が大きいことを理由に退職</span>
        をする社員もおり、企業側にとっても大きな痛手となる状況が問題視されています。介護を理由として退職した社員側の方でも、再就職までの道のりが険しいケースが多く、貴重な経験やスキルが活かせないまま過ごしている者も少なくないといわれています。
        <br />
        <br />
        このような経緯を経て、育児・介護休業法の大規模な改正が実施されることになりました。次の項目では、改正内容の詳細について順に見ていきましょう。
      </p>
      <div id="toc2">
        <h1>育児・介護休業法の改正内容</h1>
      </div>
      <p>今回の改正では、主に次の内容がポイントとして挙げられます。</p>
      <div id="toc2-1">
        <h2>育休を取得しやすい環境づくりの義務化（令和4年4月1日施行）</h2>
      </div>
      <p>
        今回の改正により、育児休業を取得しやすいような職場環境の整備や、妊娠・出産を申し出ている労働者向けの個別での対応が義務づけられます。
        <br />
        職番環境の整備措置として各企業は、
        <span>
          ①育休・産後パパ育休に関する研修の実施、②相談窓口の設置、③育休や産後パパ育休取得事例の情報提供、④育休・産後パパ育休の取得促進方針の策定・周知
        </span>
        、のうちいずれかを実施する必要があります。
        <br />
        <br />
        また、個別対応としては、育児休業の詳細を労使で適切なコミュニケーションを取りながら実施することが求められています。ただし、育児休業等の取得を控えるような圧力をかけることは言うまでもなく禁止されています。
      </p>
      <div id="toc2-2">
        <h2>有期雇用者の育児・介護休業取得要件拡大（令和4年4月1日施行）</h2>
      </div>
      <p>
        有期雇用者が育児休業や介護休業を取得する際に設けられていた
        <span>「雇用継続期間1年以上」という要件が、今回の法改正により撤廃</span>
        されました。
      </p>
      <Introduction001 />
      <div id="toc2-3">
        <h2>産後パパ育休制度の新設（令和4年10月1日施行）</h2>
      </div>
      <p>
        産後パパ育休制度とは、正式には<span>「出生時育児休業」</span>
        といいます。
        <br />
        具体的には、子供の出生後8週間の期間、いわゆる出産した女性労働者が産後休業を取得する期間において、
        <span>男性労働者が最大4週間まで取得することができる休業制度</span>です。
        <br />
        <br />
        出産後、身体の負担が大きいまま育児生活に入る母親と新生児との生活リズムを作り上げていく重要な産後期間に男性が休業を取る事で、男性が家事や育児に関わる機会を増やし、両親がともに育児・仕事を両立させた生活を確立させることを目的として新設されました。
        <br />
        <br />
        なお、産後パパ育休制度として取得した休業期間は、
        <span>育児休業期間にはカウントされず、全く別の制度と扱われます</span>
        。また、取得可能期間となる4週間については、2回に分けて取得することも可能です。
      </p>
      <div id="toc2-4">
        <h2>育児休業の分割取得（令和4年10月1日施行）</h2>
      </div>
      <p>
        育児休業は、出産した子供の数にかかわらず、取得できる回数は
        <span>「1回」</span>
        というのが原則とされています。また、期間延長のタイミングについても、子供が1歳・1歳半・2歳になる時点と限られており、状況に応じて柔軟に取得期間や回数を定めることはできませんでした。
        <br />
        <br />
        しかし、今回の法改正により、
        <span>育児休業も介護休業のように分割して取得できる</span>
        ようになりました。分割できる改正は2回までで、預け先が決まらないケースなどにおける休業期間の延長開始日も柔軟に変更できることとなったため、保育園の募集時期に合わせた休業取得や、産後パパ育休制度とあわせて活用し、産後期間以降も両親が交互に休業を取得しながら子供を育てることが可能になりました。
      </p>
      <div id="toc2-5">
        <h2>育児休業取得状況の公表（令和5年4月1日施行）</h2>
      </div>
      <p>
        常時雇用労働者数が1000人を超える、いわゆる大企業については、
        <span>育児休業等の取得状況を年に1度公表することが義務</span>
        づけられました。
        <br />
        公表しなければならない制度には、育児休業に加え産後パパ育休、看護休暇や所定外労働の免除などの3歳未満・小学校入学前の子を育てる社員向けの制度が含まれており、取得割合などをホームページなどで不特定多数の一般人が閲覧できるような形で公表することが求められています。
      </p>
      <div id="toc3">
        <h1>まとめ</h1>
      </div>
      <p>
        育児・介護休業法の改正を受け、各企業がまず行うべき対応としては、現在の社内制度の洗い出しではないでしょうか。現行の就業規則を見直し、規則の内容を法改正内容に沿った形で変更する必要があります。
        <br />
        また、自社の社員が育児休業や介護休業をどの程度取得しているかを算出し、更に取得率を高めるためにどのような職場環境づくりが必要か、また休業明けの社員に対するサポート体制は充分かを見直す必要もあるでしょう。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0042
