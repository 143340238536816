import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0042 from '../../../components/molecules/columnDetailInner0042'
// import ColumnRelationCards0042 from '../../../components/molecules/columnRelationCards0042'
import UsefulFunctionLayout019 from '../../../components/molecules/usefulFunctionLayout019'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0042 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title:
        '2022年4月以降に施行が開始！「改正育児・介護休業法」とはどんな内容？',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="2022年4月から順次施行開始！「改正育児・介護休業法」とは"
        description="育児・介護休業法の内容が改正され、令和4年4月より順次新制度が適用されるようになりました。改正までの経緯や改正内容を詳しく解説します。"
        ogUrl="https://kintaicloud.jp/column/details/C0042/"
        ogType="article"
        ogTitle="2022年4月から順次施行開始！「改正育児・介護休業法」とは"
        ogDescription="育児・介護休業法の内容が改正され、令和4年4月より順次新制度が適用されるようになりました。改正までの経緯や改正内容を詳しく解説します。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0042.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0042 />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout019 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事
        <ColumnRelationCards0042 /> */}
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0042

export const pageQuery = graphql`
  query C0042 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
