import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 60px;
  border: solid 2px #e5e5e5;
  border-radius: 5px;
  padding: 20px;

  section {
    display: flex;
    justify-content: left;
    align-items: center;

    img {
      width: 110px;
      height: 110px;
      margin: 0 20px 0 0;
    }

    .author-wrap {
      position: relative;
      width: 100%;

      p {
        line-height: 2;

        &.author-name {
          font-size: 18px;
          font-weight: 600;

          span {
            font-weight: 400;
            font-size: 14px;
          }
        }

        &.author-job {
          position: relative;
          width: 100%;
          margin-left: 14px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 10px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }

          a {
            margin-right: 15px;
          }
        }
      }
    }
  }

  .detail-txt {
    border-top: solid 1px #dcdee1;
    margin-top: 20px;
    padding-top: 15px;
    line-height: 1.8;
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：オーサ情報 加藤 知美
 * @constructor
 */
const columnAuthor002 = () => (
  <Wrapper>
    {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
    <section>
      <img src="/images/column/authors/a002.png" alt="加藤 知美" />
      <div className="author-wrap">
        <p className="author-name">加藤 知美</p>
        <p className="author-job">
          <Link
            to="https://esprimesr.wixsite.com/esprime-sr-office"
            target="_blank"
            rel="noreferrer"
          >
            エスプリーメ社労士事務所
          </Link>
          社会保険労務士
        </p>
      </div>
    </section>
    <div className="detail-txt">
      愛知県社会保険労務士会所属。総合商社、会計事務所、社労士事務所の勤務経験を経て、2014年に「エスプリーメ社労士事務所」を設立。
      <br />
      総合商社時では秘書・経理・総務が一体化した管理部署で指揮を執り、人事部と連携した数々の社員面接にも同席。会計事務所、社労士事務所勤務では顧問先の労務管理に加えセミナー講師としても活動。
      <br />
      現在は文章能力を活かしたオリジナルの就業規則・広報誌作成事業の2本柱を掲げ、専門知識を分かりやすく伝えることをモットーに企業の支援に取り組んでいる。
    </div>
  </Wrapper>
)

export default columnAuthor002
